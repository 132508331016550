<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.notifications") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :fields="fields"
        :items="notifications"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="createdAt"
        :ascending="false"
        :sortFields="sortFields"
        @row-click="(id) => router.push(`/notifications/${id}`)"
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="() => router.push('/notifications/create')"
              :icon="PlusIcon"
              id="header-notification-create-button"
              label="create_notification"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
        <template #cell(estateId)="{ value }">
          <span>{{ getEstateName(value, estates) }}</span>
        </template>
        <template #cell(type)="{ value }">
          <div :class="{ 'text-rose-600': value === 'deadline' }">
            {{ t("notification." + value) }}
          </div>
        </template>
        <template #cell(createdAt)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(sender)="{ value }">
          {{ value?.name }}
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getEstateName } from "../utils/estateUtil";
export default {
  components: { Breadcrumb, CustomTable, FormButton, PlusIcon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const fields = ref([
      {
        key: "createdAt",
        label: "date",
      },
      {
        key: "estateId",
        label: "estate_name",
      },
      {
        key: "apartment",
        label: "apartment",
      },
      {
        key: "type",
        label: "type",
      },
      {
        key: "sender",
        label: "sender",
      },
    ]);
    const searchFields = ref(["apartment", "createdAt", "type"]);
    const sortFields = ref(["apartment", "createdAt", "type"]);

    const notifications = computed(
      () => store.state.notification.notifications
    );
    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const waiting = computed(() => {
      if (store.state.notification.waiting) return true;
      if (store.state.company.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    onMounted(() => {
      store.dispatch("notification/getNotifications", company.value.id);
      if (!estates.value || !estates.value.length) {
        store.dispatch("estate/getEstates", company.value.id);
      }
    });

    return {
      company,
      estates,
      fields,
      getEstateName,
      getLocaleDate,
      locale,
      notifications,
      PlusIcon,
      router,
      searchFields,
      sortFields,
      t,
      waiting,
    };
  },
};
</script>
